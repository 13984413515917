import CardType, { CardTypeProps } from '@/Components/CardType';
import { Email, Home, Phone } from '@mui/icons-material';
import { Box, Container, Grid, Typography } from '@mui/material';


interface Props {
}


export default function ContactInfo(props: Props) {
    const goToUrl = (url: string) => () => {
        window.location.href = url
    }
    const items: Array<CardTypeProps> = [
        {
            name: 'Email',
            description: "contact@propress.fr",
            icon: <Email sx={{ fontSize: 40 }} />,
            onClick: goToUrl("mailto:contact@propress.fr"),
        },
        {
            name: 'Téléphone',
            description: "+33 1 53 27 34 00",
            icon: <Phone sx={{ fontSize: 40 }} />,
            onClick: goToUrl("tel:+33153273400"),
        },
        {
            name: 'Addresse',
            description: "15, rue Claude Tillier 75012 Paris",
            icon: <Home sx={{ fontSize: 40 }} />,
            // onClick: goToUrl("https://www.google.com/maps/uv?pb=!1s0x47e6720cda210b17%3A0xbc13af4e1bbae55c!3m1!7e115!4s%2Fmaps%2Fplace%2Fpropress%2B15%2Brue%2Bclaude%2Btillier%2B75012%2Bparis%2F%4048.8480542%2C2.3878911%2C3a%2C75y%2C0.17h%2C90t%2Fdata%3D*213m4*211e1*213m2*211so-DkgOzIOML-e6T7LerRqw*212e0*214m2*213m1*211s0x47e6720cda210b17%3A0xbc13af4e1bbae55c%3Fsa%3DX!5spropress%2015%20rue%20claude%20tillier%2075012%20paris%20-%20Recherche%20Google!15sCgIgAQ&imagekey=!1e2!2so-DkgOzIOML-e6T7LerRqw&hl=fr&sa=X&ved=2ahUKEwj3iu-dmZH-AhWEuKQKHcw-BZIQpx96BAg_EAU"),
        },
    ]

    return (
        <Container maxWidth={"md"} sx={{ flex: 1, }}>

            <Box>
                <Typography variant={"h4"} sx={{ my: 2, textAlign: 'center' }}>Contactez-nous</Typography>

                <Grid
                    container
                    columnSpacing={3}
                    rowSpacing={3}
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                // sx={{ backgroundColor: 'blue' }}
                // alignItems="stretch"
                >
                    {items.map((item, i) => {
                        return (
                            <Grid key={i} item xs={6} sm={4} md={4}   >

                                <CardType {...item} />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </Container >
    );
}