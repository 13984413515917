import { Container, Typography } from '@mui/material';


interface Props {
}

export default function CGU(props: Props) {


    return (
        <Container maxWidth={"md"} sx={{ flex: 1, }}>
            <Typography textAlign="center" variant="h3">Mentions légales</Typography>
            <Typography>Le présent site est la propriété exclusive de PROMOTION PRESSE CONSEIL(RCS Paris B 429 833 338)<br />
                dont le siège social est situé au<br />
                15 rue Claude Tillier, 75012 PARIS<br />
                Le directeur de publication est Jérôme Chikar</Typography>
            <Typography>E-mail : jc[@]propress.fr</Typography>
            <Typography variant='h6'>Hébergement</Typography>
            <Typography>OVH RCS Roubaix – Tourcoing 424 761 419 00011<br />
                Code APE 6202A<br />
                N° TVA : FR 22 424 761 419<br />
                Siège social : 2 rue Kellermann, 59100 Roubaix (France).</Typography>
            <Typography variant='h6'>Utilisation</Typography>
            <Typography>L'utilisateur s'engage à n'effectuer en aucune cas l'une des actions suivantes ou toute action visant des objections similaires :<br />

                Extraire du contenu de l'application ou site<br />
                Ouvrir le contenu avec une autre application ou site<br />
                Faire un usage commercial de l'application ou de son contenu<br />
                Usage abusif de la fonction "envoyer un message"<br />
                ProPress se réserve le droit de modifier tout moment tout ou partie des CGU sans que l'utilisateur soir nécessairement préalablement informé.<br />
                Les modifications ont pour but d'apeter les CGU aux évolutions techniques, légales et aux évolutions des ervices proposés par ProPress ou ses partenaires. Les utilsateurs soit invités à consulter régulièrement les CGU car il se peut que les modifications se soient notifiées à l'utlisateur que par leur mise en ligne.</Typography>
            <Typography variant='h6'>Cookies</Typography>
            <Typography>ProPress protége vos données personnelles lorsque vous utilisez ses services.<br />
                Nous nous employons à rendre nos services sûrs et agréables lorsque vous les utilisez.</Typography>
            <Typography>Cette politique vous fournit les éléments d'information suivants :<br />
                Information sur les cookies<br />
                Types de cookies</Typography>
            <Typography>Comment utilisons-nous les cookies ?</Typography>
            <Typography>Utilisation analytiques de nos sites et applications
                Publicité sur nos sites et applications
                Nous contacter</Typography>
            <Typography>Qu'est-ce qu'un cookie ?</Typography>
            <Typography>Un cookie est une petite quantité d'information, qui contient souvent un identifiant unique, qui est envoyé au logiciel de navigation de votre ordinateur ou terminal mobile (ci-après « terminal ») depuis un site Web. Cette information, une fois reçue, est stocké sur le disque local de votre terminal. Chaque site Web ou prestataire de service utilisé par le site Web peut envoyer ses propres cookies à votre navigateur si celui-ci est configuré pour les accepter ; cependant, et afin de protéger votre vie privée, votre navigateur n'autorise l'accès aux cookies que s'il a été envoyé par le site qui souhaite y avoir accès. Et il en refuse l'accès aux autres sites et prestataires de services. Un cookie contient des informations anonymes telles qu'un identifiant unique, le nom du site et des chiffres. Cela permet au site Web de se rappeler de vos préférences par exemple, ou de ce que contient votre caddy d'achat.</Typography>
            <Typography>Que faire des cookies ?</Typography>
            <Typography>Les cookies conservent des informations relatives à vos préférences et nous permet d'adapter nos sites Web à vos besoins particuliers. Les informations mémorisées par les cookies nous aident à analyser la façon dont vous utilisez nos sites Web afin de nous permettre de mieux les construire et vous satisfaire. Par exemple vous pouvez personnaliser notre site Web en sélectionnant un revêtement de bureau de lecture spécifique. Pour accomplir ceci, un cookie est placé sur votre terminal pour se souvenir de ce choix. Il s’agit d’un exemple basique de la façon dont nous pouvons améliorer l'expérience des utilisateurs sur nos sites Web.</Typography>
            <Typography>Modifier les réglages de votre navigateur</Typography>
            <Typography>Vous pouvez modifier la façon dont les cookies sont gérés par votre navigateur via son menu « Réglages ».<br />
                La plupart des navigateurs permet aux utilisateurs de :<br />
                • Accepter tous les cookies<br />
                • Vous avertir quand un cookie est créée<br />
                • Ne pas permettre la réception de cookie.<br />
            </Typography>
            <Typography>Si vous choisissez de ne pas recevoir de cookie, nos sites Web ne pourront fonctionner de manière optimale et la plupart des services attendus ne pourront pas être rendus. Chaque navigateur possédant des différences plus ou moins importantes, nous vous conseillons d'aller dans leur menu d'aide afin d'en savoir plus sur la gestion des cookies qu'ils proposent.</Typography>
            <Typography>Types de Cookie</Typography>
            <Typography>Cookies persistants</Typography>
            <Typography>Ce type de cookies est stocké sur le disque de votre terminal pour une durée déterminée. Ils sont utilisés pour déterminer qui vous êtes pour plus d'une session. Par exemple, si vous avez demandé à ce que l'on se rappelle de vous afin de vous éviter de vous identifier à chaque visite.</Typography>
            <Typography>Comment utilisons-nous les cookies ?</Typography>
            <Typography>Les cookies nous permettent d'identifier dans quel magasin vous êtes et la date à laquelle vous avez scanner le Qr code.<br />
                Nous n'utilisons que les cookies qui nous sont nécessaires pour vous permettre d'utiliser notre service.<br />
                Par exemple, nous utilisons les cookies pour délivrer les services comme ceci :<br />
                • Pour nous permettre de reconnaitre la date à laquelle vous avez scanné le Qr code afin de vous éviter de le scanner à chaque page<br />
            </Typography>
            <Typography variant='h6'>Données personnelles / Vie privée</Typography>
            <Typography>ProPress traite vos données lorsque vous scannez le QR code et que vous naviguez sur la page web d’atterrissage.<br />
                Les données traitées sont votre adresse IP, votre adresse e-mail, les témoins de connexions « cookies » et vos données de navigation.<br />
                Ces données sont traitées afin de vous permettre de recevoir de la presse numérique sur votre terminale mobile, de répondre à vos demandes ainsi que de sauvegarder le magasin sur lequel vous avez scanner le Qr Code afin de vous éviter de scanner le Qr code à chaque changement de page.<br />
                Elles seront conservées le temps nécessaire à l’accomplissement de l’objectif poursuivi lors de leur collecte, sauf dispositions légales contraires.<br />
                Elles pourront uniquement être communiquées aux services internes et prestataires de service intervenant dans le cadre de la finalité précédemment décrite.<br />
            </Typography>
            <Typography mt={4}>
                Conformément aux dispositions de la Loi "Informatique et Libertés" n° 78-17 du 6 janvier 1978 modifiée par la Loi n° 2016-1321 du 7 octobre 2016 pour une République numérique et au nouveau Règlement Général de Protection des données du 27 avril 2016, vous disposez lorsque ceux-ci sont applicables, d’un droit d’accès, de rectification, d’opposition, d’effacement et un droit à la limitation des traitements.
            </Typography>
            <Typography mt={4}>
                Pour exercer les droits dont vous disposez, veuillez adresser un courrier, auquel vous aurez joint votre pièce d’identité, précisant votre demande à l'adresse suivante : 15 rue Claude Tillier 75012 Paris ou par mail à l’adresse suivante : contact@propress.fr.
            </Typography>
            <Typography variant='h6'>Droits de propriété intellectuelle :</Typography>
            <Typography>
                La reproduction ou représentation, intégrale ou partielle, des pages, des données et de toute autre élément constitutif au site, par quelque procédé ou support que ce soit, est interdite et constitue sans autorisation expresse et préalable de l’éditeur une contrefaçon sanctionnée par les articles L335-2 et suivants du Code de Propriété Intellectuelle.
                Toute utilisation du contenu et de site à des fins illégales fera l’objet de poursuites judiciaires à l’égard des contrevenants.
            </Typography>
        </Container >
    );
}