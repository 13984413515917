import {
  RouteObject,
  useRoutes,
} from "react-router-dom";
import Layout from "./Layout";
import { ThemeProvider } from "@mui/system";

import usePrefersThemeColorScheme from "./hooks/usePrefersThemeColorScheme";
import CGU from "./screens/CGU";
import ContactInfo from "./screens/ContactInfo";

function App() {
  const theme = usePrefersThemeColorScheme();

  const routes: RouteObject[] = [
    {
      path: "/",
      element: <Layout />,
      children: [
        { index: true, element: <CGU /> },
        { path: "/", element: <CGU /> },
        { path: "/contact-info", element: <ContactInfo /> },
        { path: "*", element: <CGU /> },
      ],
    },
  ];
  let element = useRoutes(routes);

  return (
    <ThemeProvider theme={theme}>
      {element}
    </ThemeProvider>
  )
}

export default (App);