import styled from "@emotion/styled";
import { ButtonBase, ButtonBaseProps, Paper, Typography } from "@mui/material";
import { ReactNode } from "react";


const Name = styled(Typography)(() => ({
    variant: 'h6',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    fontSize: '1.4rem',
    fontWeight: 'bold',
}));
const Description = styled(Typography)(() => ({
    variant: 'body2',
    // overflow: 'hidden',
    textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    width: '100%',
}));
const ContainerButton = styled(ButtonBase)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    // height: 'auto',
    maxWidth: '100%',
    width: '100%',
    // backgroundColor: 'orange',
    // borderRadius: 10,
    // borderColor: 'red',
    // borderWidth: 1,
    padding: 15,
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiBackdrop-root': {
            opacity: 0.4,
        },
    },
}));
const PaperContainer = styled(Paper)(() => ({
    height: '100%',
    maxWidth: '100%',
    borderStyle: "solid",
    borderColor: 'color(--primary)',
    borderWidth: 1,
}));

export interface CardTypeProps {
    name: string,
    description?: string | ReactNode
    icon: ReactNode,
    onClick?: ButtonBaseProps['onClick']
}
const CardType = (props: CardTypeProps) => {
    const { icon, name, description, onClick } = props;
    return (
        <PaperContainer elevation={3}>
            <ContainerButton onClick={onClick}>
                {/* {
                    React.cloneElement(icon, {
                        sx: {
                            fontSize: 40
                        }
                    })
                } */}
                {icon}
                <Name>{name}</Name>
                <Description>{description}</Description>
                {/* </Box> */}
            </ContainerButton>
        </PaperContainer>
    )
}

export default CardType